import "./ViktigCarousel.scss";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ArrowLeft from "../../assets/images/arrowLeft.png";
import ArrowRight from "../../assets/images/arrowRight.png";
import CloseBtn from "../../assets/images/close.png";

// Styled components

const BlinkingCircle = styled.div`
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 50%;
  background-color: red;
  animation: blink 1.5s infinite; /* Adjust the timing for faster/slower blink */

  @media (max-width: 599px) {
    min-width: 11px;
    min-height: 11px;
    max-width: 11px;
    max-height: 11px;
  }

  @keyframes blink {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1600px) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 20px; /* Spacing between articles */
  width: calc(100% - 160px);
  margin: 0 auto;
  padding: 10px; /* Padding inside the scrolling area */
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 1rem;
    scrollbar-width: auto;
    scroll-margin: 20px;
    width: 95%;
    padding: "10px 0";
  }
`;

const Article = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 0 0 auto;
  width: auto; /* Let it shrink when needed */

  height: auto;
  min-height: 65px;
  margin: 1rem 2.5rem 0;

  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease, box-shadow 0.3s ease;
  margin-right: 1.4rem;

  &:hover {
    transform: scale(${(props) => (props.small ? 1 : "1.01")});
    // scale: ${(props) => (props.small ? 1 : "1.01")};
  }

  @media (max-width: 768px) {
    padding: 0;
    margin: 1rem 1rem 0 0;
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 62%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledArrow = styled.img`
  width: 20px;
  opacity: ${(props) => props.opacity};
  transition: opacity 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 20px;
  }
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80%",
  overflow: "auto",
  maxWidth: 1200,
  borderRadius: 3,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledCloseBtn = styled.img`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  width: 20px;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 399px) {
    right: 0.5rem;
  }
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// Carousel Component
function NyheterCarousel({ news }) {
  const carouselRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const isMobile = windowDimensions.width <= 768;

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
  };

  const handleScroll = (direction) => {
    let scrollAmount = 0;
    if (windowDimensions.width > 768) {
      scrollAmount = 310;
    } else {
      scrollAmount = 260;
    }
    if (direction === "left") {
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    } else if (direction === "right") {
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    updateScrollButtons();
    carousel.addEventListener("scroll", updateScrollButtons);
    return () => carousel.removeEventListener("scroll", updateScrollButtons);
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setOpenModal(true);
  };

  return (
    <>
      <CarouselContainer>
        <Arrows>
          <ArrowButton
            role="button"
            tabIndex="0"
            aria-label="Scroll til venstre i nyhetene"
            onClick={() => handleScroll("left")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault(); // Prevent form submission on Enter key
                handleScroll("left");
              }
            }}
            disabled={!canScrollLeft}
            style={{
              marginLeft: 25,
            }}
          >
            <StyledArrow
              opacity={canScrollLeft ? 0.3 : 0}
              src={ArrowLeft}
              alt="Left arrow"
            />
          </ArrowButton>
          <ArrowButton
            role="button"
            tabIndex="0"
            aria-label="Scroll til høyre i nyhetene"
            onClick={() => handleScroll("right")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault(); // Prevent form submission on Enter key
                handleScroll("right");
              }
            }}
            disabled={!canScrollRight}
            style={{
              marginRight: 30,
            }}
          >
            <StyledArrow
              opacity={canScrollRight ? 0.3 : 0}
              src={ArrowRight}
              alt="Right arrow"
            />
          </ArrowButton>
        </Arrows>

        <CarouselWrapper ref={carouselRef}>
          <Article
            small
            style={{
              gap: 8,
              boxShadow: "none",
              marginRight: isMobile ? ".65rem" : 0,
              paddingRight: isMobile ? 0 : 30,
            }}
          >
            <BlinkingCircle />
            <h3
              className="title3"
              tabIndex="0"
              aria-label="Oppslagstavle av viktige nyheter og innlegg"
            >
              VIKTIG!
            </h3>
          </Article>
          {/* <Article
            style={{
              cursor: "text",
              width: 100,
            }}
          >
            <BlinkingCircle />

            {isMobile ? (
              <h3 className="title3">VIKTIG INFO:</h3>
            ) : (
              <h3 className="title3">VIKTIG INFORMASJON:</h3>
            )}
          </Article> */}

          {news
            .filter((article) => article.important) // Filter articles where important is false
            .slice()
            .reverse()
            .map((article, index) => (
              <>
                <Article
                  role="button"
                  tabIndex="0"
                  aria-label={article.importantText}
                  key={index}
                  onClick={() => handleArticleClick(article)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      e.preventDefault(); // Prevent form submission on Enter key
                      handleArticleClick(article);
                    }
                  }}
                >
                  <h3 className="title2">{article.importantText}</h3>
                </Article>
              </>
            ))}
        </CarouselWrapper>
      </CarouselContainer>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {selectedArticle && (
            <>
              <StyledCloseBtn
                role="button"
                tabIndex="0"
                aria-label="Lukkeknapp"
                src={CloseBtn}
                alt="Close button"
                className="close-btn"
                onClick={() => setOpenModal(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    e.preventDefault(); // Prevent form submission on Enter key
                    setOpenModal(false);
                  }
                }}
              />
              <h2
                tabIndex="0"
                style={{
                  marginTop: 0,
                  fontFamily: "NunitoSansRegular",
                  letterSpacing: "2px",
                  marginRight: "2rem",
                }}
              >
                {selectedArticle.title}
              </h2>
              <pre
                tabIndex="0"
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "NunitoSansRegular",
                  letterSpacing: "1px",
                  whiteSpace: "pre-wrap",
                  marginBottom: 0,
                }}
              >
                {selectedArticle.text}
              </pre>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default NyheterCarousel;
